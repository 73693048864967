import { StepDefinition, IStepDefinitionOptions, IOfflineStepDefinition, IOnlineStepDefinition, type ISyncOptions } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { CleanupProgress, type ICleanupProgressJSON, type ICleanupProgressOptions } from 'o365.pwa.modules.client.steps.CleanupStepProgress.ts';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import { type SyncType } from "o365.pwa.types.ts";
import { app } from 'o365-modules';
import { getDataObjectById } from 'o365-dataobject';
import 'o365.dataObject.extension.Offline.ts';
import IndexedDBHandler from "o365.pwa.modules.client.IndexedDBHandler.ts";
import o365PWAGlobal from 'o365.pwa.modules.client.dexie.databases.O365PWAGlobal.ts';


export interface ICleanupStepDefinitionOptions extends IStepDefinitionOptions {
    dataObjects: string[];
}

export class CleanupStepDefinition extends StepDefinition implements IOfflineStepDefinition<CleanupProgress>, IOnlineStepDefinition<CleanupProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';
    public readonly IOnlineStepDefinition = 'IOnlineStepDefinition';

    public readonly dataObjects: string[] = [];

    constructor(options: ICleanupStepDefinitionOptions) {
        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            vueComponentName: 'CleanupProgressOffline',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.CleanupProgressOffline.vue');
            }
        });
        this.dataObjects = options.dataObjects;
    }

    generateStepProgress(options?: ICleanupProgressJSON | ICleanupProgressOptions, syncType?: SyncType): CleanupProgress {
        return new CleanupProgress({
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback
        });
    }
    
    public async syncOnline(options: ISyncOptions<CleanupProgress>): Promise<void> {
        const pwaState = await IndexedDBHandler.getPWAState(app.id);
        if(pwaState?.debugMergeProc){
            options.stepProgress.cleanupHasCompleted = true;
            return;
        } else {
            await this.onCleanup(options);
        }
    }

    public async syncOffline(options: ISyncOptions<CleanupProgress>): Promise<void> {
        await this.onCleanup(options);
    }

    
    private async onCleanup(options: ISyncOptions<CleanupProgress>): Promise<void> {
        try {
            options.stepProgress.cleanupHasStarted = true;


            const promiseList = new Array<Promise<Response>>();

            let hasFileTable = this.dataObjects.some((dataObjectId) => {
                const dataObject = getDataObjectById(dataObjectId, app.id);
                return dataObject.fields.fields.some((field) => {
                    return field.name === "FileRef";
                })
            });


            for (const dObject of this.dataObjects) {
                const dataObject = getDataObjectById(dObject, app.id);

                const appId = app.id;

                const requestGuid = self.crypto.randomUUID();

                const requestOptions = {
                    requestGuid: requestGuid,
                    appId: appId,
                    appIdOverride: dataObject.offline.appIdOverride,
                    databaseIdOverride: dataObject.offline.databaseIdOverride,
                    dataObjectId: dataObject.id,
                    objectStoreIdOverride: dataObject.offline.objectStoreIdOverride,
                };

                promiseList.push(fetch('/nt/api/pwa/truncate', {
                    method: 'POST',
                    body: JSON.stringify(requestOptions),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }));
            }

            if(hasFileTable){
                await o365PWAGlobal.bulkDestroyFileStoreRecordsByAppId(app.id);   
            }

            const promiseResults = await Promise.allSettled(promiseList);

            for (const promiseResult of promiseResults) {
                if (promiseResult.status === 'rejected') {
                    // TODO: Handle error
                }
            }

            options.stepProgress.cleanupHasCompleted = true;
        } catch (error: any) {
            options.stepProgress.cleanupHasErrors = true;
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(error);
            options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', `Try again or contact support if the issue does not get resolved. ${error}`));
        }
    }
}
